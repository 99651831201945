<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveFinale()">
              <div class="row">
                <div
                  class="container"
                  style="border-bottom: 1px solid black; padding: 15px"
                >
                  <router-link to="/demandeechantillon">
                    <button class="btn btn-warning" style="margin-bottom: 30px">
                      Retour
                    </button>
                  </router-link>
                  <div v-if="change == 'oui' && neww == 0">
                    <button
                      class="btn btn-info float-right"
                      id="additem"
                      style="margin-top: 10px; margin-bottom: 10px"
                      @click.prevent="newrow(echantillon._id)"
                      :disabled="neww == 1"
                      v-if="echantillon.etat_demmande=='en attente'"
                    >
                      Ajouter un article
                    </button>
                  </div>

                  <div style="margin-top: 30px">
                    <div class="row" v-if="neww === 1 && change == 'oui'">
                      <br />
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="first"
                            >Choisir l'article
                            <span class="obligatoire">*</span>
                          </label>

                          <v-select
                            :multiple="false"
                            v-model="produit_selected"
                            :closeOnClick="false"
                            :options="all_produits"
                            :reduce="(all_produits) => all_produits"
                            label="reference"
                          />
                        </div>
                      </div>
                   

                      <div class="col-md-3" v-if="produit_selected">
                      <div class="form-group">
                        <label for="first"
                          >Quantités disponible<span class="obligatoire">*</span>
                        </label>

                        <span
                        v-if="produit_selected.quantites_disponible>0"
                          class="form-control bg-success text-light"
                        >
                        {{produit_selected.quantites_disponible}}
                        </span>
                        <span
                          class="form-control bg-danger text-light"
                          v-if="produit_selected.quantites_disponible==0"
                        >
                        {{produit_selected.quantites_disponible}}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-3" v-if="produit_selected">
                        <div class="form-group">
                          <label for="first"
                            >Quantités<span class="obligatoire">*</span>
                          </label>

                          <input
                            type="number"
                            class="form-control"
                            id="quantite_total"
                            v-model="quantite_total"
                            required
                            :min="1"

                            style="height: 32px"
                          />
                        </div>
                      </div>
                      <div class="col-md-1">
                        <br />
                        <div class="form-group" style="margin-top: 5px">
                          <button
                            class="btn btn-success float-right"
                            @click.prevent="validRow()"
                            :disabled="
                            produit_selected.length === 0  || quantite_total<1
                          "
                          >
                            <i class="mdi mdi-shield-check"> </i>
                          </button>
                        </div>
                      </div>

                      <div class="col-md-1">
                        <br />
                        <div class="form-group" style="margin-top: 5px">
                          <button
                            class="btn btn-danger float-right"
                            @click.prevent="annuler()"
                          >
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <table class="table table-bordered" v-if="change == 'oui'">
                  <thead>
                    <tr>
                      <th class="py-1">Photo</th>
                      <th class="py-1">Réference Produit</th>
                      <th class="py-1">Quantités demandé</th>
                      <th class="py-1">Quantités disponible</th>
                      <th class="py-1"  v-if="echantillon.etat_demmande=='en attente'">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(produit, index) in echantillon.produits"
                      :key="index"
                    >
                      <td class="py-1">
                        <img
                          v-if=" produit.produit_id.photo!=null"
                            width="80px"
                            variant="light-primary"
                            :src="
                              require('@/images/' + produit.produit_id.photo)
                            "
                            class="badge-minimal"
                          />

                          <img
                          v-if=" produit.produit_id.photo==null"
                            width="80px"
                            variant="light-primary"
                            :src="
                              require('@/images/product.png')
                            "
                            class="badge-minimal"
                          />
                      </td>
                      <th>
                        {{ produit.produit_id.reference }}
                      </th>

                      <td class="py-1">
                        <input
                          class="form-control"
                          type="number"
                          required
                          :min="1"

                          placeholder="Entrer la nouvelle quantité"
                          v-model="produit.quantites"
                          :disabled="echantillon.etat_demmande!= 'en attente'"
                        />
                      </td>
                      <th>
                        {{ produit.produit_id.quantites_disponible }}
                      </th>
                      <td   v-if="echantillon.etat_demmande=='en attente'">
                        <button
                          class="btn btn-danger"
                          @click.prevent="deleteItem(produit.produit_id._id)"
                          v-if="echantillon.produits.length > 1"
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  class="btn btn-success float-right"
                  style="margin-top: 15px"
                  v-if="echantillon.etat_demmande=='en attente'"
                  
               
                >
                  Enregistrer les modifications
                </button>
            
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  data() {
    return {
      roles: [],
      search: "",
      echantillon: [],
      produits: [],
      all_produits: [],
      change: "",
      neww: 0,
      produit_selected: "",
      quantite_total: "",
    };
  },
  mounted() {
    this.change = "oui";
  },

  created() {
    this.getechantillon();
  },
  methods: {
    saveFinale() {
      this.echantillon.change = this.change;

      HTTP.post(
        "echantillons/showupdatechantillon/" + this.$route.params.id,
        this.echantillon
      ).then(
        (res) => {
          if (res.data.type == "valide_change") {
            Toast.fire({
              icon: "success",
              title: "Demmande d'echantillon modifié",
            });
          }
        },
        (err) => {}
      );
    },

    deleteItem(id) {
      this.echantillon.produits = this.echantillon.produits.filter(
        (element) => element.produit_id._id != id
      );

      Toast.fire({
        position: "top-center",
        icon: "success",
        title: "Produit Suprimée",
      });
    },

    validRow() {
      if (this.all_produits.length != 0) {
        let newobject = {
          produit_id: this.produit_selected,
          quantites: this.quantite_total,
        };
        this.echantillon.produits.push(newobject);
        this.annuler();

      }
    },
    annuler() {
      this.produit_selected = "";
      this.quantite_total = "";
      this.neww = 0;
    },
    getechantillon() {
      HTTP.get("echantillons/getoneechantillons/" + this.$route.params.id)
        .then((response) => {
          this.echantillon = response.data;
        })
        .then((err) => console.log(err));
    },

    newrow() {
      HTTP.get("produits/getproduits").then(
        (response) => {
          this.all_produits = response.data;
          
          this.echantillon.produits.forEach((produit_echant) => {
            this.all_produits = this.all_produits.filter(
              (element) => element._id != produit_echant.produit_id._id
            );
          });
          if (this.all_produits.length == 0) {
            Toast.fire({
              position: "top-center",
              icon: "error",
              title: "Stock non valide",
            });
          } else {
            this.neww = 1;
          }
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },
  },
};
</script>
